import React, { MutableRefObject, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DeliveryField from './delivery-field'
import Loader from './loader'
import { useRenderingsContext } from './context/renderings-context'
import { ImageDTO, RenderingDTO } from './dtos'
import { Tabs } from './enums'
import { ISaveResult } from './interfaces'

interface DeliveryFieldsListProps {
  isLoading: boolean
  selectedTab: Tabs
  onDataFieldClick: (
    rendering: RenderingDTO,
    dataFieldName?: string,
    isArray?: boolean
  ) => void
  onSetListRef: (
    rendering: RenderingDTO,
    ref: MutableRefObject<HTMLElement>
  ) => void
  onDatasetChange: (
    key: string,
    value: string | ImageDTO,
    renderingId: number
  ) => Promise<ISaveResult>
}

const useStyles = makeStyles(() => ({
  deliveryFieldsList: {
    maxWidth: '850px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }
}))

const DeliveryFieldsList = ({
  isLoading,
  selectedTab,
  onDataFieldClick,
  onSetListRef,
  onDatasetChange
}: DeliveryFieldsListProps): React.ReactElement => {
  const renderingRefs = useRef(new Map())
  const { displayRenderingsData, setActiveRenderingId } = useRenderingsContext()
  const classes = useStyles()

  useEffect(() => {
    const handleScroll = () => {
      let closestRenderingId = null
      let minDistanceFromTop = Infinity

      renderingRefs.current.forEach((renderingElement, key) => {
        if (!renderingElement) {
          return
        }
        const rect = renderingElement.getBoundingClientRect()
        const distanceFromTop = Math.abs(rect.top)

        if (distanceFromTop < minDistanceFromTop) {
          minDistanceFromTop = distanceFromTop
          closestRenderingId = key
        }
      })
      setActiveRenderingId(closestRenderingId)
    }

    window.addEventListener('scroll', handleScroll)

    // Initial trigger
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={classes.deliveryFieldsList}>
      {isLoading && <Loader />}
      {!isLoading &&
        displayRenderingsData.map(rendering => (
          <div
            key={rendering.id}
            ref={el => renderingRefs.current?.set(rendering.id, el)}
          >
            <DeliveryField
              rendering={rendering}
              renderingRefs={renderingRefs}
              onDataFieldClick={onDataFieldClick}
              onSetListRef={onSetListRef}
              selectedTab={selectedTab}
              onDatasetChange={onDatasetChange}
            />
          </div>
        ))}
    </div>
  )
}

export default DeliveryFieldsList
